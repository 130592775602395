import React from "react"
import { Navbar, Button, Container } from "react-bootstrap"
import Logo from '../images/svg/logo-ex.svg'
import styled from 'styled-components'
import { Link } from "gatsby"

const StyledButton = styled(Button)``

export default function Sidenav() {
  return (
    <Navbar expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to={`/`}>
            <Logo />
          </Link>
        </Navbar.Brand>
        <div className="">
          <StyledButton className="btn btn-primary" href={`../pwa-exness.pdf`}>Download the report</StyledButton>
        </div>
      </Container>
    </Navbar>
  )
}