import React from "react"
import { Nav, Button } from "react-bootstrap"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components'

const SideMenu = styled.div`
  .nav {
      color: #000;
      
      @media (min-width: 576px) {
        /* padding-right: 1rem; */
        /* border-right: 1px solid black;
        margin-right: 1rem; */
      }
      a {
          color: #000; 
          font-size: 14px;
      }
      a + a {
        margin-top: 1em;
      }
      a.active {
        font-weight: 700
      }

  }
`

const StyledButton = styled(Button)`
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media (min-width: 576px) {
      margin-top: 3rem;
      margin-bottom: 0;
    }
`
export default function Sidenav( props, { data } ) {
  // const setActive = (props) => {
  //   console.log('props: ');
  //   console.log(props);
  // }
  return (
    <SideMenu className={props.className}>
      <Nav defaultActiveKey="/home" className="flex-column">
        <h2 className="h4 font-weight-light mt-5 mb-3">Psychology of Trading</h2>
        <StaticQuery
          query={graphql`
            {
              allMdx(sort: { fields: [frontmatter___date], order: ASC }) {
                nodes {
                  id
                  slug
                  excerpt(pruneLength: 250)
                  frontmatter {
                    title
                    date(formatString: "YYYY MMMM Do")
                  }
                }
              }
            }
          `}
          
          render={
            
            data => data.allMdx.nodes.map(item =>
              (
                <Link key={item.id} to={`/${item.slug}`} activeClassName="active">
                  {item.frontmatter.title}
                </Link>
              )
            )
          }
        />
        <StyledButton variant="primary">Download the report</StyledButton>
      </Nav>
    </SideMenu>
  )}

  /*
  
  */