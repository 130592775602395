import React, { useState, useRef, useEffect } from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import styled from 'styled-components'
import Navbar from "./Navbar"
import Sidenav from "./Sidenav"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import "../styles/_main.scss"
import { ArrowDownSquareFill } from '@styled-icons/bootstrap'

const MobileMenu = styled(Sidenav)`
  display: block;
  z-index: 1000;
  @media (max-width: 575.98px) { 
    padding: 0 1em;
  }
  &.is-active {
    display: none;
  }
  h2,
  button {
    display: none;
  }
  .nav a, .nav a:link {
    color: white;
    font-size: 16px;
    margin: 0 auto;
    display: block;
    width: 100%;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
  }
  .nav {
    a + a {
      padding-top: 14px;
      margin-top: 14px;
    }
  }
`

const MobileNavHeader = styled.div`
  @media (max-width: 575.98px) { 
    padding: 0 1em;
  }
  > div {
    margin: 0 auto;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
  }
`

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
      ref.current = value;
  });
  return ref.current;
};

export const Layout = ({ children }) => {
  const { title } = useSiteMetadata();
  const [isActive, setActive] = useState("false");
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (location !== prevLocation) {
        setActive(false);
    }
  }, [location, prevLocation, setActive]);

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Navbar />
      <div className="d-block d-lg-none">
        <MobileNavHeader className="bg-warning text-dark pt-2 pb-2">
          <div className="row no-gutters align-items-center">
            <Link to="/" className="col text-dark active">{title}</Link>
            <div className="col pt-1 pb-1 text-right">
            <button className="border-0 bg-transparent" onClick={handleToggle}>
              <span className="mr-2">Menu</span><ArrowDownSquareFill size="32" />
            </button>
            </div>
          </div>
        </MobileNavHeader>
        <MobileMenu className={`${isActive ? "" : "is-active"} bg-secondary w-100 position-absolute text-light pt-4 pb-4 border-warning border-bottom`} />
      </div>
      {children}
    </div>
  );
};